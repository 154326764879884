.boxContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8px;
}

.infoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.box {
    border-radius: 6px;
    min-width: fit-content;
    display: flex;
    flex-wrap: nowrap;
}

.infoButton, .box {
    background: white;
    padding: 16px;
    position: absolute;
    bottom: 32px;
    right: 8px;
    z-index: 1;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);

    &.minimized {
        bottom: 92px;
    }
    &.length1 {
        bottom: calc(110px + 32px + 10px);
    }
    &.length2 {
        bottom: calc(164px + 32px + 10px);
    }
    &.length3orMore {
        bottom: calc(216px + 32px + 10px);
    }
}

.mainTitle {
    color: #696969;
    font-size: 18px !important;
    font-weight: bold !important;
    margin-bottom: 8px !important;
    line-height: 1.2 !important;
}
