.tempVariationChart .modebar {
    left: 50%;
}
 .tempVariationChart .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 740px;
 }

.notFoundTempVariation {
    height: 740px;
}


