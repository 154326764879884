.main {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    background: #eaeaea;

    &.home {
        overflow: hidden;
    }
}
.container {
    display: flex;
    justify-content: center;
}