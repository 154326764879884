.root {
    border: none;
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    overflow: hidden;
    display: block;
}
.controls {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    > * {
        pointer-events: all;
    }
}
.loadingLinear {
    position: absolute;
    left: 125px;
    bottom: 15px;
    right: 300px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
    &.visible {
        opacity: 1;
    }
}