.windRoseChart .modebar {
    left: 22%;
}
 .windRoseChart .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 350px;
 }

.notFoundRoseWindData {
    height: 420px;
}



