.root {
    border: none;
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    overflow-x: hidden;
    display: block;
    height: 650px;
    width: 100%;
}
