body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeaea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sentry-error-embed-wrapper{
  z-index: 2500 !important;
}

#notistack-snackbar {
  width: calc(100% - 38px) !important;
}
